import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import classes from './Support.module.scss';
import Container from "../../components/Container";
import Slide from "../../components/Slide";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";

const Support = () => {
	const [focusY, setFocusY] = useState(0)
	const [modal, setModal] = useState({ isOpen: false, header: '', body: '' });

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<React.Fragment>
			{modal.isOpen &&
				<Modal data={{
					isOpen: modal.isOpen,
					title: modal.header,
					body: modal.body,
					footer: "Hello Footer"
				}}
				       closeModal={() => { setModal({ isOpen: false }) }}
				/>
			}
			<Header />
			<Container>
				<Slide exchangeForm focusY={focusY}>
					<article className="slide__text_block">
						<h1>Privacy Policy</h1>
						<h3>GENERAL</h3>
						<p className={`${classes.spacing}`}>Bitnixie UAB and its affiliates (hereinafter, "Bitnixie UAB", "we", "us" or "our") are committed to protecting and respecting your privacy.</p>
						<p className={`${classes.spacing}`}>This Privacy Policy (together with our Terms of Service) governs our collection, processing and use of your Personal Information. We define "Personal Information" as information which identifies you personally, e.g. your name, address, e-mail address, trades etc.</p>
						<h4>The purpose of this Privacy Policy is to inform you of:</h4>
						<ul className={`${classes.spacing}`}>
							<li>the kinds of Personal Information which we may collect about you and how it may be used;</li>
							<li>our use of information regarding IP Addresses and our use of cookies;</li>
							<li>any disclosure of Personal Information to third parties;</li>
							<li>your ability to correct, update and delete your Personal Information;</li>
							<li>the security measures we have in place to prevent the loss, misuse, or alteration of Personal Information under our control.</li>
						</ul>
						<h3>GATHERING AND USE OF PERSONAL INFORMATION</h3>
						<p className={`${classes.spacing}`}>We may collect your Personal Information if you use the Site, open an Account to use the Platform or perform any Transactions on the Platform. The types of Personal Information which we collect may include:</p>
						<ul className={`${classes.spacing}`}>
							<li>your name;</li>
							<li>your photographic identification;</li>
							<li>your address;</li>
							<li>your phone number;</li>
							<li>your e-mail address;</li>
							<li>your banking details including account numbers;</li>
							<li>your date of birth;</li>
							<li>your transactions.</li>
						</ul>
						<h4>We may use your Personal Information for the following purposes:</h4>
						<ul className={`${classes.spacing}`}>
							<li>to allow you to open and operate an Account on the Platform;</li>
							<li>to enable you to complete Transactions on the Platform;</li>
							<li>if you contact us, to reply to your queries;</li>
							<li>to analyse use of our Site;</li>
							<li>as required for regulatory purposes;</li>
							<li>to provide you with information about products and promotions that may be of interest to you, from ourselves and third parties, although only if you have specifically agreed to receive such information;</li>
							<li>for market research e.g. surveying our Users' needs and opinions on issues, such as our performance etc.</li>
						</ul>
						<p className={`${classes.spacing}`}>We will process your Personal Information only for the purpose(s) for which it has been provided to us.</p>
					</article>
				</Slide>
			</Container>
			<Footer openModal={(newstate) => { setModal(newstate) }} />
		</React.Fragment>
	);
};

export default Support;
