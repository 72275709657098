import './App.css'
import './media.scss'
import './css/gilroy.css'
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Slide from './components/Slide';
import Footer from './components/Footer';
import Container from './components/Container';
import Modal from './components/Modal';

function App() {
  const [focusY, setFocusY] = useState(0)
  const [modal, setModal] = useState({ isOpen: false, header: '', body: '' });

  const handleScroll = (event) => {
    if (window.innerWidth < 767) return;
    const focus = window.pageYOffset + window.innerHeight / 2;
    if (focus < window.innerHeight)
      setFocusY(0);
    if (focus < window.innerHeight * 2 && focus > window.innerHeight)
      setFocusY(1);
    if (focus < window.innerHeight * 3 && focus > window.innerHeight * 2)
      setFocusY(2);
    if (focus > window.innerHeight * 3)
      setFocusY(3);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  })

  return (

    <div className="App">
      <>
        {modal.isOpen &&
          <Modal data={{
            isOpen: modal.isOpen,
            title: modal.header,
            body: modal.body,
            footer: "Hello Footer"
          }}
            closeModal={() => { setModal({ isOpen: false }) }}
          />
        }
        <Header />
        <Container id='main' bordered>

          <Slide exchangeForm focusY={focusY}>
            <article className="slide__text_block">
              <h1 className="text__gradient">Build the Future with </h1>
              <h1>Blockchain technology and Currency</h1>
              <p>Online service <b>BITNIXIE</b> allows to buy, sell and exchange various types of cryptocurrencies - Bitcoin, Ethereum and other. We accept international currencies. Low rates, fast processing of applications, technical support.</p>
            </article>
          </Slide>

        </Container>


      </>
      <Container id='about' bordered>
        <Slide>
          <article className="slide__text_block">
            <h1>About us</h1>
            <p><b>BITNIXIE</b> is a crypto-exchange platform for both individual and legal
              persons providing exchange service between virtual (crypto) and fiat currencies.
              Our activity complies with EU 4th Anti-Money Laundering Directive (AMLD) and Lithuanian AML legislation.
             Bitnixie UAB is based in Lithuania and regulated by Lithuanian Financial Crime Investigation Service under the Ministry of Interior (FCIS).</p>
          </article>
        </Slide>
      </Container>
      <Container id='services' bordered>
        <Slide>
          <article className="slide__text_block">
            <h1>Bitnixie services</h1>
            <p><b>CRYPTOCURRENCY PURCHASE AND EXCHANGE SERVICES</b></p>
            <p>
              The offered service allows to buy and sell cryptocurrencies with the bank cards Visa and MasterCard.
              The service is active around the clock without any weekends 24/7.
            </p>
            <p><b>Advantages:</b></p>
            <ul>
              <li>Cumulative discount system</li>
              <li>Referal system for bonuses and discounts</li>
              <li>Quality service of a European level</li>
              <li>Financial services license</li>
            </ul>
            <p><b>Exchange currencies:</b></p>
            <ul>
              <li><b>Fiat:</b> EUR</li>
              <li><b>Crypto:</b> BTC, Ethereum and other</li>

            </ul>
            <p><b>Commissions:</b></p>
            <table className='table'>
              <tbody>
              <tr>
                <td>BUY CRYPTO: 2% of the amount</td>
                <td>Min 1 USDT</td>
                <td>Max 1000 USDT</td>
              </tr>
              <tr>
                <td>SELL CRYPTO: 2% of the amount</td>
                <td>Min 1 USDT</td>
                <td>Max 1000 USDT</td>
              </tr>
              <tr>
                <td>SEND CRYPTO: 2% of the amount</td>
                <td>Min 1 USDT</td>
                <td>Max 1000 USDT</td>
              </tr>
              <tr>
                <td>SEND FIAT: 2% of the amount</td>
                <td>Min 1 USDT</td>
                <td>Max 1000 USDT</td>
              </tr>
              </tbody>
            </table>
          </article>
        </Slide>
      </Container>
      <>
        <Container id='contact'>
          <Slide>
            <article className="slide__text_block">
              <div style={{ marginBottom: 20 }}>
                <h1>Contact us</h1>
                <p><b>Address:</b> Konstitucijos pr. 21A, LT-08130, Vilnius, Lithuania</p>
                <p><b>E-mail:</b> contact@bitnixie.lt</p>
              </div>

              <iframe className='_map-container'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2305.6520699749976!2d25.269321815720456!3d54.69815008029812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd9400c744ffb3%3A0x6ec3e1efc8a007d4!2sKonstitucijos%20pr.%2021a%2C%20LT-08130%20Vilnius%2C%20Lithuania!5e0!3m2!1sen!2slv!4v1679942132643!5m2!1sen!2slv"
                  allowFullScreen="" loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
            </article>
          </Slide>

        </Container>
        <Footer openModal={(newstate) => { setModal(newstate) }} />
      </>

    </div>
  );
}

export default App;
