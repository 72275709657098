export const privacy = (
  <div class="text">
    <p>GENERAL</p>

    <p>
      BITNIXIE&nbsp;and its affiliates (hereinafter, "BITNIXIE",
      "we", "us" or "our") are committed to protecting and respecting your
      privacy.
    </p>

    <p>
      This Privacy Policy (together with our Terms of Service) governs our
      collection, processing and use of your Personal Information. We define
      "Personal Information" as information which identifies you personally,
      e.g. your name, address, e-mail address, trades etc.
    </p>

    <p>The purpose of this Privacy Policy is to inform you of:</p>

    <p>
      the kinds of Personal Information which we may collect about you and how
      it may be used;
    </p>

    <p>our use of information regarding IP Addresses and our use of cookies;</p>

    <p>any disclosure of Personal Information to third parties;</p>

    <p>
      your ability to correct, update and delete your Personal Information; and
    </p>

    <p>
      the security measures we have in place to prevent the loss, misuse, or
      alteration of Personal Information under our control.
    </p>

    <p>GATHERING AND USE OF PERSONAL INFORMATION</p>

    <p>
      We may collect your Personal Information if you use the Site, open an
      Account to use the Platform or perform any Transactions on the Platform.
      The types of Personal Information which we collect may include:
    </p>

    <p>your name;</p>

    <p>your photographic identification;</p>

    <p>your address;</p>

    <p>your phone number;</p>

    <p>your e-mail address;</p>

    <p>your banking details including account numbers;</p>

    <p>your date of birth; and</p>

    <p>your transactions.</p>

    <p>We may use your Personal Information for the following purposes:</p>

    <p>to allow you to open and operate an Account on the Platform;</p>

    <p>to enable you to complete Transactions on the Platform;</p>

    <p>if you contact us, to reply to your queries;</p>

    <p>to analyse use of our Site;</p>

    <p>as required for regulatory purposes;</p>

    <p>
      to provide you with information about products and promotions that may be
      of interest to you, from ourselves and third parties, although only if you
      have specifically agreed to receive such information;
    </p>

    <p>
      for market research e.g. surveying our Users' needs and opinions on
      issues, such as our performance etc.
    </p>

    <p>
      We will process your Personal Information only for the purpose(s) for
      which it has been provided to us.
    </p>

    <p>IP ADDRESSES</p>

    <p>
      We may collect information about your computer, including where available
      your IP address, operating system and browser type, for system
      administration and to report aggregate information to our advertisers.
      This is statistical data about our users' browsing actions and patterns
      and does not identify any individual.
    </p>

    <p>COOKIES</p>

    <p>
      We use a browser feature known as a "cookie", which assigns a unique
      identification to your computer. Cookies are typically stored on your
      computer's hard drive. Information collected from cookies is used by us to
      evaluate the effectiveness of our Site, analyse trends, and administer the
      Platform. The information collected from cookies allows us to determine
      such things as which parts of our Site are most visited and difficulties
      our visitors may experience in accessing our Site. With this knowledge, we
      can improve the quality of your experience on the Platform by recognising
      and delivering more of the most desired features and information, as well
      as by resolving access difficulties. We also use cookies and/or a
      technology known as web bugs or clear gifs, which are typically stored in
      emails to help us confirm your receipt of, and response to, our emails and
      to provide you with a more personalised experience when using our Site.
    </p>

    <p>
      We use third party service provider(s), to assist us in better
      understanding the use of our Site. Our service provider(s) will place
      cookies on the hard drive of your computer and will receive information
      that we select that will educate us on such things as how visitors
      navigate around our site, what products are browsed, and general
      Transaction information. Our service provider(s) analyses this information
      and provides us with aggregate reports. The information and analysis
      provided by our service provider(s) will be used to assist us in better
      understanding our visitors' interests in our Site and how to better serve
      those interests. The information collected by our service provider(s) may
      be linked to and combined with information that we collect about you while
      you are using the Platform. Our service provider(s) is/are contractually
      restricted from using information they receive from our Site other than to
      assist us.
    </p>

    <p>
      By using our Site you are agreeing that we may use cookies for the
      purposes set out above.
    </p>

    <p>DISCLOSURE OF PERSONAL INFORMATION</p>

    <p>
      We use the Personal Information for the purposes indicated at the time you
      provide us with such information, and/or otherwise for the purposes set
      out in this Privacy Policy and/or as otherwise permitted by law.
    </p>

    <p>
      We may make available the Personal Information that you provide to us to
      our affiliates, agents, representatives, trusted service providers and
      contractors for these limited purposes.
    </p>

    <p>
      We may also share Users’ Personal Information with financial institutions,
      insurance companies or other companies in the case of a merger,
      divestiture, or other corporate re-organisation.
    </p>

    <p>
      We may also share Users' Personal Information with law enforcement or
      regulatory agencies, as may be required by law.
    </p>

    <p>
      Any third party which receives or has access to Personal Information shall
      be required by us to protect such Personal Information and to use it only
      to carry out the services they are performing for you or for BITNIXIE,
      unless otherwise required or permitted by law.
    </p>

    <p>
      We will ensure that any such third party is aware of our obligations under
      this Privacy Policy and we will enter into contracts with such third
      parties by which they are bound by terms no less protective of any
      Personal Information disclosed to them than the obligations we undertake
      to you under this Privacy Policy or which are imposed on us under
      applicable data protection laws.
    </p>

    <p>CORRECTION⁄UPDATING⁄DELETION OF PERSONAL INFORMATION</p>

    <p>
      You have the right to access your Personal Information and to require the
      correction, updating and blocking of inaccurate and/or incorrect data by
      sending an email to us at: contact@bitnixie.lt.
    </p>

    <p>
      You may also request the deletion or destruction of both the Account and
      Personal Information by sending an email to us at: contact@bitnixie.lt.
      BITNIXIE&nbsp;will action your request only where this is not
      inconsistent with its legal and regulatory obligations.
    </p>

    <p>
      Upon your written request, we will inform you of the Personal Information
      relating to you that we hold and the use and general disclosure of your
      Personal Information. We will also give you a copy of the Personal
      Information we have retained. There may be a minimal charge for accessing
      your Personal Information.
    </p>

    <p>SECURITY</p>

    <p>
      We have implemented security measures to ensure the confidentiality of
      your Personal Information and to protect your Personal Information from
      loss, misuse, alteration or destruction. Only authorised personnel of{" "}
      <strong>BITNIXIE</strong> have access to your Personal
      Information, and these personnel are required to treat the information as
      confidential. The security measures in place will, from time to time, be
      reviewed in line with legal and technical developments.
    </p>

    <p>RETENTION OF PERSONAL INFORMATION</p>

    <p>
      We will hold your Personal Information only for as long as it is necessary
      for us to do so, having regard to the purposes described in this Privacy
      Policy and our own legal and regulatory requirements. In accordance with
      our record keeping obligations we will retain Accounts and Personal
      Information for, at least a period of five years after they are closed by
      Users.
    </p>

    <p>LINKS</p>

    <p>
      There may be links from our Site to other sites and resources provided by
      third parties. This Privacy Policy applies only to our Site. Accessing
      those third party sites or sources requires you to leave our Site. We do
      not control those third party sites or any of the content contained
      therein and you agree that we are in no way responsible or liable for any
      of those third party sites, including, without limitation, their content,
      policies, failures, promotions, products, services or actions and/or any
      damages, losses, failures or problems caused by, related to or arising
      from those sites. We encourage you to review all policies, rules, terms
      and regulations, including the privacy policies, of each site that you
      visit.
    </p>

    <p>MARKETING</p>

    <p>
      You have the right to ask us not to process your Personal Information for
      marketing purposes. You can exercise your right to prevent such processing
      by checking certain boxes on the forms we use to collect your Personal
      Information. You can exercise the right at any time by contacting us at
        contact@bitnixie.lt.
    </p>

    <p>CHANGES</p>

    <p>
      Our Site policies, content, information, promotions, disclosures,
      disclaimers and features may be revised, modified, updated, and/or
      supplemented at any time and without prior notice at the sole and absolute
      discretion of <strong>BITNIXIE</strong>. If we change this Privacy
      Policy, will take steps to notify all users by a notice on our Site and
      will post the amended Privacy Policy on the Site.
    </p>
  </div>
);

export const aml = (
  <div class="text">
    <p>BITNIXIE ANTI-MONEY LAUNDERING POLICY</p>

    <p>General provisions</p>

    <p>
      1.1 Current AML policy have been developed in accordance with the
      applicable legislation of the Republic of Lithuania.
    </p>

    <p>
      1.2 AML policy is a set of internal rules and regulations that is used by
      the Company in order to check and reveal documentation and information
      regarding the operation that are Under obligatory control and other
      operations with money or property that may be in any way connected to the
      money laundering or finance of terrorism and the provision of such
      information to the state authorities.
    </p>

    <p>1.3 The AML policy is a document that:</p>

    <p>
      1.3.1 Regulates the organisation of work, regarding the prevention of
      legalisation of illegally obtained funds (money laundering) and financing
      of terrorism;
    </p>

    <p>
      1.3.2 Sets the obligations and obligatory procedures for the employees
      regarding internal control;
    </p>

    <p>
      1.3.3 Sets the terms for the fulfilment of obligations by the employees
      and sets the authorized control persons.
    </p>

    <p>1.4 The AML policy contains further programs;</p>

    <p>1.4.1 The program for the overview of internal control methods;</p>

    <p>1.4.2 Program for the implementation of internal control methods;</p>

    <p>
      1.4.3 Client and persons associated with clients identification program;
    </p>

    <p>1.4.4 Risk analysis program;</p>

    <p>
      1.4.5 Program for the regulation of commercial relations with clients;
    </p>

    <p>
      1.4.6 Program regulation obligatory actions in case there are suspicions
      regarding money laundering;
    </p>

    <p>1.4.7 Correspondence exchange program;</p>

    <p>1.4.8 Information documental fixation program;</p>

    <p>1.4.9 Transaction refusal program;</p>

    <p>1.4.10 Company employee preparation program;</p>

    <p>1.4.11 Internal control program;</p>

    <p>
      1.4.12 Document maintenance program, regarding documentation that was
      obtained during the internal control program execution.
    </p>

    <p>
      1.5 The company is providing service in accordance with the licenses
      issued by the Lithuanian Anti-Money Laundering bureau
    </p>

    <p>The company provides services through the web portal BITNIXIE.</p>

    <p>
      1.6 The person responsible for the accurate implementation of the present
      policy is named by the management board of the company.
    </p>

    <p>
      <strong>2. Organisational basis for control methods</strong>
    </p>

    <p>
      2.1 The person responsible for the implementation of the provisions of the
      present documents is named by the management board of the company.
    </p>

    <p>
      2.2 In order to adequately implement the current policy and considering
      the volume of clients and associated risk levels, the company formed a
      separate group, including the member of the board, the head accountant and
      the head of the legal department, in order to implement AML policies,
    </p>

    <p>
      2.3 All subsidiaries and structural parts are accountable before the
      authorized group in the field of suspicious transactions. All the matters
      regarding the initial client identification are handled by the
      corresponding structural parts of the company.
    </p>

    <p>
      2.4 The management board names a contact person between the company
      BITNIXIE and the Financial inspection of Estonia. The contact
      person shall perform the following tasks:
    </p>

    <p>
      Analysis of the performed or planned transaction regarding their possible
      connection to money laundering or finance of terrorism;
    </p>

    <p>
      Presentation to the financial inspection of any data regarding clients,
      their associated persons and transactions that may connected to illegal
      activity.
    </p>

    <p>Preparation</p>

    <p>Fulfilment of other obligations under the present AML policy.</p>

    <p>
      <strong>3. Implementation of control methods</strong>
    </p>

    <p>3.1 Control methods are used in the following areas.</p>

    <p>- during the initiation of commercial relationship with the client;</p>

    <p>
      - in any case when the sum of the transaction exceeds 6400 EUR or an
      equivalent in any other currency;
    </p>

    <p>- if there is any doubt that the provided data is accurate;</p>

    <p>- if the planned transaction is needlessly complex;</p>

    <p>
      - if there is any reason to suspect that the transaction is connected with
      money laundering or finance of terrorism or any other form of illegal
      activity or is considered a high-risk transaction in accordance with the
      risk evaluation procedure set by the current policy.
    </p>

    <p>
      3.2 Additional control methods are implemented in the situation when the
      Client changes the conditions of the transaction, increasing the risk
      level:
    </p>

    <p>If the transaction serves no rational purpose;</p>

    <p>If the transaction is financially irrational;</p>

    <p>
      If the same type of transactions is repeated multiple times over a short
      period;
    </p>

    <p>
      Should the client refuse to provide information without giving a reason
      for the refusal or should the client express unusual concern with the
      matters of confidentiality;
    </p>

    <p>
      Should the Client decide to change the transaction in a manner that is not
      a usual practice of BITNIXIE;
    </p>

    <p>
      Should the client express unreasonable hurry to carry out the transaction;
    </p>

    <p>
      Should the client implement changes into the transaction conditions
      shortly before it is performed;
    </p>

    <p>Should the client prove impossible to contact;</p>

    <p>
      Should there be any information that the data provided by the client is
      false or inaccurate;
    </p>

    <p>
      In case of absence of any association between the activities of the Client
      and the planned transaction;
    </p>

    <p>Should the planned transaction be needlessly complicated.</p>

    <p>3.3 Additional methods for the control of a suspicious transaction:</p>

    <p>
      Receipt from the client of necessary explanations and confirmations that
      clarify the purpose of the transaction;
    </p>

    <p>
      Implementation of an increased monitoring in accordance with the present
      policy regarding all the transactions of the Client in order to confirm if
      they are in any way connected to money laundering or finance of terrorism.
    </p>

    <p>
      <strong>
        4. Client and their associated persons identification program&nbsp;
      </strong>
    </p>

    <p>
      4.1 The initial identification of the client is made when the client fills
      out the information form available at BITNIXIE webpage that is
      obligatory for filling when the client wishes to use BITNIXIE
      services. Should BITNIXIE deem it necessary it may request for the
      document to be provided in a form certified by a notary and confirmed by
      an apostille, that confirms the client data.
    </p>

    <p>4.1.1 Regarding the physical persons the following is confirmed:</p>

    <p>Name;</p>

    <p>Surname;</p>

    <p>Fathers name (when applicable to the national tradition);</p>

    <p>Citizenship;</p>

    <p>Date of birth, personal ID number;</p>

    <p>ID document data;</p>

    <p>Place of residence;</p>

    <p>Occupation;</p>

    <p>Reason for creating an account with BITNIXIE;</p>

    <p>Contact information – telephone number, e-mail address.</p>

    <p>
      4.1.2 BITNIXIE has the right to check the accuracy of the
      information provided by the client and ask for the provision of the
      required document in order to check to following:
    </p>

    <p>Is the document still legally valid;</p>

    <p>Does the photo on it check out;</p>

    <p>Does the persona ID code correspond to the gender and age;</p>

    <p>
      Should there be any doubt regarding the document the state authorities
      that issued the document may be contacted in order to obtain the necessary
      confirmation.
    </p>

    <p>
      4.1.3 Should the Client present an ID document, a copy is made, the
      quality of which allows to review the data contained in the document. No
      copy is made if the Client presented a copy of a document certified by a
      notary and with an apostille attached.
    </p>

    <p>
      4.1.4 When the identity of a physical person is confirmed it is also
      confirmed if he is a state official;
    </p>

    <p>
      The state official is person who has a state appointed position in any
      country of the European Union or any other country or who holds a position
      in an International organisation. Further data regarding the state
      officials is to be gathered:
    </p>

    <p>
      List of the closest associates and relatives if such information is
      publically available;
    </p>

    <p>
      Confirmation of the property and sources of income that are meant to be
      used in the transaction;
    </p>

    <p>Preparation of an inquiry to the proper database;</p>

    <p>Preparation of an inquiry to the state supervision institutions;</p>

    <p>
      Decision regarding the start of the commercial relationship with the state
      official is made by a person named in accordance with provision 2.1 of
      this policy.
    </p>

    <p>
      4.1.5 Only official sources may be used to check the provided information,
      such as state registries or foreign representatives. Other sources may be
      used if there is no doubt regarding their accuracy and competence.
    </p>

    <p>
      4.1.6 Trustworthy Client recommendation does not replace the proper review
      of information.
    </p>

    <p>
      4.1.7 Identification of a physical person that acts as a representative is
      made in accordance with the provisions of this policy.
    </p>

    <p>
      4.1.8 Identification of a physical person is not a one time procedure. The
      information regarding the physical person should be checked constantly and
      updated when necessary. Should there be any doubt regarding the accuracy
      of information, BITNIXIE has the right to block an account.
    </p>

    <p>
      4.1.9 Should there be any doubt, the actual beneficiary must be revealed.
    </p>

    <p>
      4.2 A Client that is a legal person must fill out the information form
      that is available on the webpage of BITNIXIE, providing all the
      necessary information. The following information must be obtained during
      the registration of a legal person:
    </p>

    <p>
      4.2.1 Regarding the legal persons the following information is gathered:
    </p>

    <p>Name of the legal entity;</p>

    <p>Registration number;</p>

    <p>Legal address and the address of principal activity;</p>

    <p>Corporate form;</p>

    <p>Full information regarding legal representatives;</p>

    <p>Beneficiaries</p>

    <p>
      4.2.2 Should BITNIXIE deem it necessary the provided information
      may be checked. The review of information is made by the use of public
      registries and databases or by sending inquiries to the state authorities.
      Certificate from a registry may be replaced by an authorised access to the
      registry.
    </p>

    <p>
      4.2.3 All documents issued by foreign state authorities must be legalised
      or have an apostille, except from the documents from Latvia, Lithuania,
      Poland, Russian or Ukraine. The present provision may be amended if
      Estonia signs and ratifies an international agreement with other states,
      that will make apostille unnecessary.
    </p>

    <p>
      4.2.4 The control of the legal entity management board or other similar
      control organ must also be carried out, also regarding beneficiaries and
      their representatives. Should the information provided by the Client not
      be trustworthy enough the information may be controlled by the means of an
      inquiry to state officials or international organisations.
    </p>

    <p>
      4.2.5 All the provided information is gathered and carefully studied if
      the company has any subsidiaries, representatives or in any way connected
      to countries that do not cooperate in the field of international
      opposition to money laundering and finance of terrorism, or if those
      states are considered low tax jurisdictions.
    </p>

    <p>
      4.2.6 If the legal entity is an international organisation, then its field
      of activity must be confirmed by the provisions of documentation regarding
      activities in Estonia. The information in the documents must be checked.
    </p>

    <p>
      4.3 If the legal entity acts as a representative of another entity, then
      the information of that other entity must also be gathered in accordance
      with this policy.
    </p>

    <p>
      4.3.1 Only an authorised legal representative may register a legal entity
      on the website of BITNIXIE. The authorised representative must
      provide sufficient documents proving his authority to represent the legal
      person. Should the provided documents fail to provide the required
      information or if there is any doubt regarding their accuracy, no
      commercial relationship may be started and account may be blocked. The
      document proving the authority must contain further information:
    </p>

    <p>Scope of rights;</p>

    <p>Date the authority was granted and for what period;</p>

    <p>Reason the authority was granted.</p>

    <p>
      4.4 Any transaction with the legal person requires the registration of the
      current beneficiary.
    </p>

    <p>
      4.4.1 Should a person have more than one beneficiary, then all other
      beneficiaries must also be registered.
    </p>

    <p>
      4.4.2 Should it prove impossible to clarify, who is the beneficiary in the
      company, then all owners of the company must be confirmed and sufficient
      explanation must be provided by the Client.
    </p>

    <p>
      4.4.3 Special care should be used when confirming the actual beneficiary
      if the planned transaction may in any way be connected to the increase of
      the risk due to the field of activity of the legal person, state of
      registration, type of provided services and nature of the transaction,
      also if the legal person is registered or connected to a low tax
      jurisdiction.
    </p>

    <p>
      4.5 The non-resident legal person must abide to the same identification
      process as stated above. BITNIXIE has the right to deny
      transaction if the legal person is a resident of a country whose
      legislation violates the provisions of this AML policy.
    </p>

    <p>
      4.6 Should the risk of the transaction be considered low or should there
      be reasons to consider the risk of commercial relations low, a simplified
      control method of client identification may be used, by checking the data
      through the publically available databases that can be considered
      trustworthy. Aside from the low risk level that was given under the risk
      analysis following the provisions of part 5 of this Document, additional
      reasons to consider the transaction to be low risk are the following:
    </p>

    <p>- the Client is an Lithuanian public legal person;</p>

    <p>
      - state organisation or other organisation performing public functions
      that is acting in Estonia or EU;
    </p>

    <p>- EU institutions;</p>

    <p>
      - Credit institution that is active in Estonia and EU, where the
      provisions of directive 2015/849 are applicable.
    </p>

    <p>
      - physical person resident of Estonia or of other country that has solid
      anti-money laundering legislation following the Financial Action Task
      Force reports.
    </p>

    <p>
      Use of the simplified control method does not free the company from the
      obligation to make sure that the transaction is transparent.
    </p>

    <p>
      4.7 Should the risk of the transaction be considered high or should there
      be reasons to consider the risk of commercial relations high, a higher
      level control method of client identification should be used, by
      requesting the client to provide additional information and documents that
      can rule our the risk. Information is also to be provided to the financial
      inspection of Estonia to get more instructions. Aside from the high-risk
      level that was given under the risk analysis following the provisions of
      part 5 of this Document, additional reasons to consider the transaction to
      be high risk are the following:
    </p>

    <p>- unusual circumstances for the transactions;</p>

    <p>- client operates with high volumes of cash;</p>

    <p>- a legal person client has hidden owners or recipient type shares;</p>

    <p>- the structure of the legal person is too complex and confusing;</p>

    <p>
      - new or unknown goods are the subject of the transaction and transaction
      specifics are unusual;
    </p>

    <p>- transaction is made for anonymity purposes;</p>

    <p>- unknown third parties make payments following the transaction;</p>

    <p>
      - other circumstances mentioned in article 37 of the Lithuanian money
      laundering and terrorism finance prevention act.
    </p>

    <p>
      <strong>5. Risk evaluation.</strong>
    </p>

    <p>
      5.1 Any Client and his planned transaction are evaluated regarding the
      risk factor of money laundering and finance of terrorism.
    </p>

    <p>
      5.1.1 During the risk evaluation the Client is given the risk status, from
      lowest to highest, by the means of evaluating further risk factors:
    </p>

    <p>- Client corporate structure and field of activity;</p>

    <p>- Is the Client a state official or connected to one;</p>

    <p>- Is the Client represented by a legal person;</p>

    <p>- If the beneficiary of a physical person is a third person;</p>

    <p>- There are problems identifying the beneficiary;</p>

    <p>- The Client is connected to low tax jurisdictions;</p>

    <p>- The client is subject to international sanctions;</p>

    <p>- The Client is a typical client;</p>

    <p>- There is positive experience of cooperation with the Client;</p>

    <p>- Term of cooperation;</p>

    <p>- Nature of the planned transaction;</p>

    <p>- Amount of the transaction;</p>

    <p>- Are the Clients stable or constantly shifting;</p>

    <p>- Problems during identification procedures.</p>

    <p>
      5.2 The nature of the transaction must be evaluated to set the risk
      status.
    </p>

    <p>
      5.2.1 The transaction may be awarded with a low, medium or high risk
      status depending on the following factors:
    </p>

    <p>The transaction involves currency exchange;</p>

    <p>The transaction involves alternative payment methods;</p>

    <p>The transaction involves gambling;</p>

    <p>The transaction involves rarities or exclusive goods;</p>

    <p>The transaction involves innovations;</p>

    <p>The transaction involves commercials;</p>

    <p>The transaction involves company establishment or management.</p>

    <p>
      5.3 Geographical circumstances are to be evaluated (geographical risk).
    </p>

    <p>
      5.3.1 The transaction may be awarded with a low, medium or high risk
      status depending on the following geographical actors:
    </p>

    <p>
      The transaction involves low tax jurisdictions. This entails a company
      registered at the low tax jurisdiction or services provided at the low tax
      jurisdiction.
    </p>

    <p>
      The transaction involves a state that does not cooperate in the field of
      money laundering prevention and finance of terrorism.
    </p>

    <p>
      The transaction involves a state with high crime rate or trafficking of
      drugs;
    </p>

    <p>The transaction involves a state with high level of corruption;</p>

    <p>
      The transaction involves a state that is a subject to international
      sanctions;
    </p>

    <p>Other factors that may increase the geographical risk.</p>

    <p>
      5.4 Along with the risks connected to the Client, risks regarding his
      partners or associated persons are also evaluated.
    </p>

    <p>
      5.5 The risk evaluation is performed by giving each risk group a status on
      a three-point scale:
    </p>

    <p>
      Risk is considered low, if no category has a risk factor and the
      transaction is clear;
    </p>

    <p>
      Risk is considered medium if there are risk factors, by the transactions
      itself is clear, though there are suspicions that all of the risk factors
      together may indicate money laundering or finance of terrorism;
    </p>

    <p>
      Risk is considered high if there are multiple risk factors and the
      transactions itself is not clear.
    </p>

    <p>
      Overall result is achieved by adding the factor of each category, whereas
      risks regarding client and partner is multiplied by two and then the whole
      sum is divided by a factor of 4.
    </p>

    <p>If the sum is 2 or lower the risk is low;</p>

    <p>If the sum is 2 to 2,75 the risk is medium;</p>

    <p>If the sum is higher than 2,75 the risk is high.</p>

    <p>
      If the risk in any category is high, the overall risk is considered high
      no matter the overall sum.
    </p>

    <p>
      <strong>6. Commercial relationship with the Client&nbsp;</strong>
    </p>

    <p>
      6.1 Any commercial relationship with the Client is initiated only after
      the Client approved of the User Agreement on the webpage of Bitnixie UAB
       and agreed to act in accordance with the present AML policy.
    </p>

    <p>
      6.2 Before the start of commercial relationship the following must be set:
    </p>

    <p>Nature of the planned agreement;</p>

    <p>Terms of the planned agreement;</p>

    <p>Volume of the planned agreement.</p>

    <p>Received information is kept in a written form.</p>

    <p>
      6.3 Should any actions being taken by the representative of a Client,
      BITNIXIE is entitled to request the documents confirm his
      authority.
    </p>

    <p>
      <strong>
        7. Actions in case of suspicions regarding money laundering and
        obligation to provide information
      </strong>
    </p>

    <p>
      7.1 Should there be any suspicion before the initiation of commercial
      relationship or during the use of control methods, that the transactions
      may be connected to money laundering or finance of terrorism, then further
      cooperation is impossible and the Clients account must be blocked for an
      unspecified term.
    </p>

    <p>
      7.2 Any suspicions are registered and analysed by the contact person. The
      Anti Money Laundering Bureau of Estonia and the Financial inspection of
      Estonia must be notified without delay along with the results of the
      analysis.
    </p>

    <p>
      7.3 All information regarding any transactions that is 32000 EUR or higher
      must be provided to the Anti Money Laundering Bureau of Estonia and the
      Financial inspection of Estonia.
    </p>

    <p>
      7.4 Should the denial to perform the transaction may result in damages to
      the Client or the arrest of a person suspected in money laundering or
      terrorism, the transaction may be delayed or performed on the condition
      that the Financial Inspection is informed without delay, with the
      provision of all information necessary to check the transaction and the
      reasoning for delay.
    </p>

    <p>
      7.5 All the information provided to the Financial Inspection is kept in an
      archive in accordance with provision 13 of the present document, including
      the data analysis results.
    </p>

    <p>
      7.6 Persons suspected in money laundering or finance of terrorism should
      not be provided any information regarding the suspicions.
    </p>

    <p>
      <strong>8. Correspondence exchange</strong>
    </p>

    <p>
      8.1 Should it be deemed by the management to be necessary for the control
      method implementation, correspondence exchange with third persons may be
      initiated, including banks and other financial institutions if that will
      allow to gather more accurate information.
    </p>

    <p>
      8.2 Correspondence exchange must be drawn up in the form of a two-way
      agreement, including the used control methods.
    </p>

    <p>
      8.3 There can be no correspondence exchange with shadow banks, unlicensed
      organisations or with organisations situated in jurisdictions whose
      legislation is not up to the international standards in the field of Anti
      money laundering legislation and prevention of finance of terrorism.
    </p>

    <p>
      <strong>9. Information recording program</strong>
    </p>

    <p>
      9.1 Information recording program sets the obligation for the company
      employee who performed the transaction to draw up an internal document
      contain all specifics of the transaction, that must include:
    </p>

    <p>
      9.1.1 Category of the transactions, reasons why the transaction may be
      considered a high-risk transactions;
    </p>

    <p>
      9.1.2 Details of the transactions including the volume of the transaction
      and currency;
    </p>

    <p>9.1.3 Details on the persons involved in the transactions;</p>

    <p>9.1.4 Information on the involved Company employee and his signature;</p>

    <p>9.1.5 Act date;</p>

    <p>
      9.1.6 Written note of the company management board member or of other
      authorized person regarding a transaction performed under this act.
    </p>

    <p>
      9.1.7 Information regarding any additional methods of control use in
      regards to the transactions;
    </p>

    <p>
      9.1.8 There is no pre-arranged act form. Acts are made by hand by each
      employee and are presented to the member of the management board for
      review.
    </p>

    <p>
      <strong>10. Transaction denial program&nbsp;</strong>
    </p>

    <p>
      10.1 If the Client despite his obligation did not present the required
      document in accordance with the control methods, the applicable
      legislation and this policy, the Client will be denied in the performance
      of the transaction.
    </p>

    <p>
      10.2 Should the Client fail to present information regarding the source of
      the funds upon the request of BITNIXIE, the transactions shall be
      denied.
    </p>

    <p>
      10.3 Information regarding the transaction denial must be kept in
      accordance with provision 13 of this policy:
    </p>

    <p>
      Information on the circumstances for the transaction denial and account
      blocking.
    </p>

    <p>Circumstances for the denial of the start of commercial cooperation;</p>

    <p>
      Any circumstances regarding the end of commercial cooperation in
      accordance with provisions 7.1 and 7.2 of the present policy.
    </p>

    <p>
      Information that was the reason the state authorities were notified in
      accordance with section 32 of the Money Laundering and terrorism finance
      prevention act.
    </p>

    <p>
      10.4 Decision regarding the denial to perform the transaction may be
      reversed if the Client provides the required information and documents or
      if such shall be set by the decision of the holloing organs:
    </p>

    <p>Control organ of the Republic of Estonia;</p>

    <p>Competent Court of Estonia.</p>

    <p>
      <strong>11. Company employees training program&nbsp;</strong>
    </p>

    <p>
      11.1 The program regarding the training of BITNIXIE employees in
      the field of Anti Money Laundering Legislation and Prevention of finance
      of terrorism is made in accordance to the applicable legislation and
      includes proper instructions for the employee regarding control methods
      and information analysis. Any employee must be properly instructed by the
      authorised workers of BITNIXIE during the period of a month from
      the start of work.
    </p>

    <p>
      <strong>12. Internal control review program</strong>
    </p>

    <p>
      12.1 The internal control review program ensures that the employees and
      members of BITNIXIE abide to the provisions of the applicable
      legislation in the field of income legalisation obtained by illegal means
      and terrorism finance. The program ensures that the employees abide to the
      internal company rules and regulations in the field of internal control.
    </p>

    <p>12.2 Internal controls sets the following:</p>

    <p>
      12.2.1 Regularly, at least once every six months, internal checks must be
      carried out regarding the proper implementation of internal regulations
      and applicable legislation.
    </p>

    <p>
      12.2.2 BITNIXIE member of the board must be provided with regular
      written reports regarding all violations of internal regulations in the
      field of money laundering prevention and prevention of finance of
      terrorism.
    </p>

    <p>
      12.2.3 All violations revealed during checks must be properly handled by
      the means chosen by the management board member.
    </p>

    <p>
      <strong>13. Document maintenance program</strong>
    </p>

    <p>
      13.1 All documents connected to the client identification procedure and
      all information regarding the start of commercial cooperation must be
      maintained in the company archive for no less than five years.
    </p>

    <p>
      13.2 All documents that became the reason for notifying state authorities
      must be maintained for no less than five years.
    </p>

    <p>
      13.3 All information on the inquiries made in order to abide to the
      provisions of the applicable legislation must be kept for no less than
      five years from the start of commercial cooperation. If the identity was
      confirmed by the means of a digital document, then the picture of the face
      and signature is kept for no less than five years from the date of the end
      of commercial cooperation.
    </p>

    <p>
      13.4 The documents must be maintained in a form that allows their written
      reproduction, so that they would be readily available for financial
      control or for other state authorities in accordance with the applicable
      legislation, should they be required for use in civil, criminal or
      arbitrary proceedings.
    </p>

    <p>
      Internal control rules set confidentiality standards for the information
      that was received during identification process and other means prescribed
      by the applicable legislation.
    </p>
  </div>
);

export const terms = (
  <div class="text">
    <p>
      These Terms of Service constitute an electronic agreement between you
      (hereinafter the “User”) and BITNIXIE located at Konstitucijos pr. 21A, LT-08130, Vilnius, Lithuania
      (hereinafter the “BITNIXIE”) that applies to your use of this website,
      all services, products and content provided by BITNIXIE.
    </p>

    <p>
      As used herein, “BITNIXIE” refers to the company BITNIXIE,
      including but not limited to, its owners, directors, investors, employees
      or other related parties.
    </p>

    <p>
      These Terms of Service is an important document, which the User must
      consider carefully when choosing whether access an accountto use services,
      products and content of BITNIXIE. Please read these Terms of Service
      carefully before agreeing to them.
    </p>

    <p>
      The User is solely responsible for understanding and complying with any
      and all laws, rules and regulations of his/her specific jurisdiction that
      may be applicable to the User in connection with the use of all services,
      products and content of BITNIXIE.
    </p>

    <p>
      By accepting these Terms of Service, you agree with and accept all
      policies published on this website.
    </p>

    <p>
      IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE, DO NOT ACCESS THIS SITE AND
      DO NOT USE ANY OF BITNIXIE’S SERVICES, PRODUCTS AND CONTENT.
    </p>

    <p>1. DEFINITIONS</p>

    <p>
      1.1. Capitalized terms used in these Terms of Service shall be interpreted
      in accordance with the definitions provided below:
    </p>

    <p>
      1.1.1. Account: means an account registered by the User on the
      BITNIXIE Platform.
    </p>

    <p>
      1.1.2. Buyer: means the User who submits an Order to buy Cryptocurrencies
      through the Platform.
    </p>

    <p>
      1.1.3. Commission: means a fee charged by or on behalf of any third party
      (e.g. bank, non-bank financial institution, payment service provider,
      etc).
    </p>

    <p>
      1.1.4. Cryptocurrency: means peer-to-peer decentralized digital
      representation of value (e.g. bitcoin, litecoin, ethereum, etc.).
    </p>

    <p>
      1.1.5. Deposit: means a Transaction involving a transfer of Funds to the
      Account.
    </p>

    <p>
      1.1.6. Fiat currency: means government-issued currency, that is designated
      as legal tender in its country of issuance through government decree,
      regulation, or law.
    </p>

    <p>1.1.7. Funds: means Cryptocurrency, Fiat currency.</p>

    <p>
      1.1.8. Order: means a User’s instruction to buy or sell Cryptocurrency on
      certain conditions.
    </p>

    <p>
      1.1.9. Platform: means an environment created by BITNIXIE that allows
      to trade Cryptocurrencies.
    </p>

    <p>1.1.10. Services: mean all and any service provided by BITNIXIE.</p>

    <p>1.1.11. Site: means BITNIXIE website at https://BITNIXIE.</p>

    <p>
      1.1.12. Storage: means BITNIXIE service, which includes storing of (i)
      balances (in Cryptocurrency) from the abandoned Accounts, (ii) no longer
      supported cryptocurrencies.
    </p>

    <p>
      1.1.13. Transaction fee: means a fee which is payable to BITNIXIE for
      each completed Transaction.
    </p>

    <p>
      1.1.14. Transaction Price: means the total price paid by the Buyer in
      respect to each Transaction performed via the Service.
    </p>

    <p>
      1.1.15. Transaction: means (i) transfer of Cryptocurrencies by the User to
      his/her Account (“Deposit Transaction”); (ii) transfer of Cryptocurrencies
      among the Users (“Trading Transaction”), (iii) withdrawal of
      Cryptocurrencies from his/her Account (“Withdrawal Transaction”), (iv)
      exchange of Cryptocurrencies to fiat currencies and vice versa.
    </p>

    <p>
      1.1.16. User: means a person or an entity, who uses the Services, agreed
      to the Terms of Service and is a holder of an Account.
    </p>

    <p>
      1.1.17. Voucher: means an alphanumeric redeemable code, which can be used
      to transfer Cryptocurrency between Accounts.
    </p>

    <p>
      1.1.18. Withdrawal: means a Transaction involving a transfer of Funds from
      the User’s Account to his/her bank account or to account opened in any
      other financial institution.
    </p>

    <p>
      1.1.19 Exchange: means the purchase of Cryptocurrency for Fiat Currency or
      sale of Cryptocurrency for Fiat Currency.
    </p>

    <p>
      1.1.20 Trading transfer: means a transfer of Cryptocurrency from one user
      to another user.
    </p>

    <p>
      1.1.21 Deposit transaction: means a deposit of a Cryptocurrency by the
      user to his account.
    </p>

    <p>
      1.2. Where the context so admits words denoting the singular shall include
      the plural and vice versa.
    </p>

    <p>2. SCOPE OF THE SERVICES</p>

    <p>
      2.1. The Services allow all Users of the Platform to trade
      Cryptocurrencies with BITNIXIE.
    </p>

    <p>
      2.2. Depending on the User’s place of residence, the User may not be able
      to use all the functions of the Site. It is the User’s responsibility to
      follow those rules and laws in his/her place of residence and/or place
      from which the User accesses this Site.
    </p>

    <p>3. USER’S RIGHTS AND RESPONSIBILITIES</p>

    <p>
      3.1. The User has the right to enter and use the Site and the Services, as
      long as he/she agrees to and actually complies with the Terms of Service.
      By using the Site, the User agrees to accept and comply with the terms and
      conditions stated herein.
    </p>

    <p>
      3.2. The User undertakes to read the entire Terms of Service carefully
      before using the Site or any of the services provided by BITNIXIE.
    </p>

    <p>
      3.3. The User undertakes to comply with any and all applicable laws and
      regulations related to the use of the Services.
    </p>

    <p>
      3.4. The User undertakes to monitor all and any changes on his/her
      Account, including but not limited to the balance matters.
    </p>

    <p>
      3.5. The User undertakes to immediately (i.e. right after the moment of
      discovery) inform BITNIXIE about any unusual, suspicious, unclear or
      abnormal changes on his/her Account. In case of late informing or
      non-informing the User will be liable for the breach of the Terms of
      Service and BITNIXIE will have the right to take any further steps
      accordingly, including but not limited to report to the relevant state
      authorities.
    </p>

    <p>
      3.6. The User undertakes to notify BITNIXIE immediately of any
      unauthorized use of his/her Account or password, or any other breach of
      security by email addressed to contact@bitnixie.lt. Any User who violates
      the mentioned rules may be terminated, and thereafter held liable for
      losses incurred by BITNIXIE or any user of the Site.
    </p>

    <p>
      3.7. The User undertakes not to use the Service to perform criminal
      activity of any sort, including but not limited to, money laundering,
      illegal gambling operations, financing terrorist organizations, or
      malicious hacking.
    </p>

    <p>
      3.8. The User is responsible for any and all damages caused and all
      liability actions brought against BITNIXIE for infringement of third
      party rights or violation of applicable laws.
    </p>

    <p>
      3.9. Nothing in the Terms of Service excludes or limits the liability of
      the User for fraud, death or personal injury caused by its negligence,
      breach of terms implied by operation of law, or any other liability which
      may not by law be limited or excluded.
    </p>

    <p>
      3.10. Users are solely responsible for determining whether any
      contemplated Transaction is appropriate for them based on their personal
      goals, financial status and risk willingness.
    </p>

    <p>
      3.11. All added payment instruments to your Account, may it be a bank
      account, credit card, debit card, or others must be named after the
      Account holder. Any attempt otherwise will be considered as fraud.
    </p>

    <p>4. USER’S REPRESENTATIONS AND WARRANTIES</p>

    <p>
      4.1. By registering an Account, User expressly represents and warrants
      that he/she:
    </p>

    <p>
      follows the rules and laws in his/her country of residence and/or country
      from which he/she accesses this Site and Services;
    </p>

    <p>has accepted these Terms of Service;</p>

    <p>
      is at least 18 years of age, has full legal capacity and has the right to
      accept these Terms of Service.
    </p>

    <p>
      4.2. The User represents and warrants that he will only use the Platform
      to perform Transactions in accordance with the conditions set forth in
      these Terms and that they are duly authorized and have the legal capacity
      to enter into Transactions on the Platform.
    </p>

    <p>
      4.3. The User represents and warrants that cryptocurrency deposited to the
      Account and Fiat currency used for exchange belong to the User and derived
      from legal sources.
    </p>

    <p>
      4.4. The User represents and warrants than he/she will withdraw any
      cryptocurrency from his/her Account only to his/her wallets, otherwise
      BITNIXIE does not hold any liability for the consequences of such
      withdrawal.
    </p>

    <p>
      4.5. The User represents and warrants that all Transactions being carried
      out do not violate the rights of any third party or applicable laws.
    </p>

    <p>5. BITNIXIE'S RIGHTS AND RESPONSIBILITIES</p>

    <p>
      5.1. BITNIXIE has the right to suspend User’s Account and block all
      Cryptocurrency, Fiat currency in case of non-fulfilment or unduly
      fulfilment of the Terms of Service by the User.
    </p>

    <p>
      5.2. BITNIXIE undertakes to provide Services with the utmost effort,
      due care and in accordance with these Terms of Service.
    </p>

    <p>
      5.3. BITNIXIE’s responsibility shall be limited to using reasonable
      technical efforts to ensure the receipt of the cryptocurrency transferred.
    </p>

    <p>
      5.4. To the extent permitted by law, BITNIXIE is not responsible for
      any damages, loss of profit, loss of revenue, loss of business, loss of
      opportunity, loss of data, indirect or consequential loss unless the loss
      suffered is caused by a breach of the Terms of Service by BITNIXIE.
    </p>

    <p>
      5.5. BITNIXIE is not responsible for any malfunction, breakdown, delay
      or interruption of the Internet connection, or any reason why our site is
      unavailable at any given time.
    </p>

    <p>
      5.6. In the case of fraud, BITNIXIE undertakes to report all the
      necessary information, including names, addresses and all other requested
      information, to the relevant authorities dealing with fraud and breaches
      of the law. Users recognize that their account may be frozen at any time
      at the request of any competent authority investigating a fraud or any
      other illegal activity.
    </p>

    <p>
      5.7. Nothing in these terms excludes or limits the responsibility of
      BITNIXIE for fraud, death or personal injury caused by its negligence,
      breach of terms implied by operation of law, or any other liability which
      may not be limited or excluded by law.
    </p>

    <p>6. BITNIXIE’S REPRESENTATIONS AND WARRANTIES</p>

    <p>
      6.1. BITNIXIE shall provide the Services with reasonable care and
      skill and in accordance with these Terms of Service.
    </p>

    <p>7. INTELLECTUAL PROPERTY RIGHTS</p>

    <p>
      7.1. All content on this Site is the property of BITNIXIE and is
      protected by copyright, patent, trademark and any other applicable laws,
      unless otherwise specified hereby.
    </p>

    <p>
      7.2. The trademarks, trade names, service marks and logos of BITNIXIE
      and others used on the Site (hereinafter the “Trademarks”) are the
      property of BITNIXIE and its respective owners. The software,
      applications, text, images, graphics, data, prices, trades, charts,
      graphs, video and audio materials used on this Site belong to
      BITNIXIE. The Trademarks and other content on the Site should not be
      copied, reproduced, modified, republished, uploaded, posted, transmitted,
      scraped, collected or distributed in any form or by any means, no matter
      manual or automated. The use of any content from the Site on any other
      site or a networked computer environment for any other purpose is strictly
      prohibited; any such unauthorized use may violate copyright, patent,
      trademark and any other applicable laws and could result in criminal or
      civil penalties.
    </p>

    <p>
      7.3. BITNIXIE supports the protection of intellectual property. If you
      would like to submit (i) a trademark claim for violation of a mark on
      which you hold a valid, registered trademark or service mark, or (ii) a
      copyright claim for material on which you hold a bona fide copyright,
      please send us an email to contact@bitnixie.lt.
    </p>

    <p>8. USER VERIFICATION</p>

    <p>
      8.1. Identification and verification procedures (also known as 'Know Your
      Customer' or ' KYC') are required for all Transactions, which involve
      Funds. On its sole discretion BITNIXIE may apply mentioned KYC
      procedures to Users, who make Transactions exclusively in Cryptocurrency.
      If the User refuses to provide required documents and information,
      BITNIXIE reserves the right to immediately terminate Services
      provision to the User.
    </p>

    <p>
      8.2. You undertake to provide BITNIXIE with correct and relevant
      documents and personal information contained therein. In case the User
      provides counterfeit documents and false personal information, such
      behavior will be interpreted as a fraudulent activity.
    </p>

    <p>
      8.3. You hereby authorise BITNIXIE to, directly or indirectly (through
      third parties) make any inquiries we consider necessary to check the
      relevance and accuracy of the information provided for verification
      purposes.
    </p>

    <p>9. ILLEGAL TRANSACTIONS</p>

    <p>
      9.1. BITNIXIE reserves the right to suspend or terminate your
      BITNIXIE Account at any time if we reasonably believe to be required
      to do so by the law or in order to comply with recommendations issued by a
      relevant government authority or recognized body for the prevention of
      financial crime.
    </p>

    <p>
      9.2. It is strictly forbidden to use the Account for any illegal purposes.
      BITNIXIE will report any suspicious activity to the relevant law
      enforcement.
    </p>

    <p>
      9.3. The User shall ensure that it does not use the Services for
      transactions relating to:
    </p>

    <p>
      money laundering, terrorist financing, proliferation of weapons of mass
      destruction;
    </p>

    <p>human trafficking;</p>

    <p>
      any goods or services that are illegal or the promotion, offer, or
      marketing of which is illegal or that are offered in connection with
      illegal, obscene or pornographic content, depict children or minors in
      sexual postures, or depict means of propaganda or signs of
      unconstitutional organisations glorifying war or violating human dignity;
    </p>

    <p>
      any goods or services, the promotion, offer, or marketing of which would
      violate copyrights, industrial property, rights or other rights of any
      person;
    </p>

    <p>archeological findings;</p>

    <p>drugs, narcotics, or hallucinogens;</p>

    <p>weapons of any kind;</p>

    <p>illegal gambling services;</p>

    <p>Ponzi, pyramid or any other “get rich quick” schemes;</p>

    <p>goods that are subject to any trade embargo;</p>

    <p>
      media that is harmful to minors and violates laws and, in particular, the
      provision is respect of the protection of minors;
    </p>

    <p>body parts or human remains;</p>

    <p>protected animals or protected plants;</p>

    <p>weapons or explosive materials; or</p>

    <p>any other illegal goods, services or transactions.</p>

    <p>10. ACCOUNT SECURITY</p>

    <p>
      10.1. The User is responsible for maintaining the confidentiality of
      his/her Account’s credentials, including, but not limited to a password,
      email, wallet address, balance, and of all activity including Transactions
      made through the Account.
    </p>

    <p>
      10.2. BITNIXIE personnel will never ask the User to disclose his/her
      password. Any message the User receives or website that he/she visits that
      asks for the password, other than the BITNIXIE Site, should be
      reported to BITNIXIE. If the User is in doubt whether a website is
      genuine, it is required to ensure the website is SSL compliant (Security
      Certificate Validation is shown in the address bar of a web browser).
    </p>

    <p>
      10.3. It is advisable to change the User’s password regularly (at least
      every three (3) to six (6) months) in order to reduce the risk of a
      security breach in relation to the Account. BITNIXIE also advises the
      User not to choose a password that is easily guessed from information
      someone might know or gather about the User or a password that has a
      meaning. The User must never allow anyone to access his/her Account or
      watch the User accessing his/her Account.
    </p>

    <p>
      10.4. If the User has any security concerns about his/her Account, login
      details, password or other security feature being lost, stolen,
      misappropriated, used without authorization or otherwise compromised, the
      User is advised to change the password. The User must contact Support
      Service without undue delay on becoming aware of any loss, theft,
      misappropriation or unauthorized use of the Account, login details,
      password or other security features. Any undue delay in notifying
      BITNIXIE may not only affect the security of the Account, but may
      result in the User being liable for any losses as a result. If the User
      suspects that someone else accessed his/her Account, the User should also
      contact an appropriate government agency and report the incident.
    </p>

    <p>
      10.5. The User must take reasonable care to ensure that his/her e-mail
      account(s) are secure and only accessed by the User, as his/her e-mail
      address may be used to reset passwords or to communicate with the User
      about the security of the Account. BITNIXIE cannot be liable for the
      breach of an e-mail account resulting an unauthorized Transaction to be
      executed with proper confirmation. In case any of the e-mail addresses
      registered with the User’s Accounts are compromised, the User should
      without undue delay after becoming aware of this contact Support Service
      and also contact his/her e-mail service provider.
    </p>

    <p>
      10.6. Irrespective of whether the User is using a public, a shared or
      his/her own computer to access the Account, the User must always ensure
      that his/her login details are not stored by the browser or cached or
      otherwise recorded. The User should never use any functionality that
      allows login details or passwords to be stored by the computer he/she is
      using.
    </p>

    <p>
      10.7. Additional products or services the User uses may have additional
      security requirements and the User must familiarize with those as notified
      to him/her.
    </p>

    <p>11. TERMINATION OF THE TERMS OF SERVICE</p>

    <p>
      11.1. The User may terminate the Terms of Service, and close his Account
      at any time, following settlement of any pending transactions.
    </p>

    <p>
      11.2. The User also agrees that BITNIXIE may, by giving notice, at its
      sole discretion terminate his/her access to the Site and to his/her
      Account, including without limitation, our right to: limit, suspend or
      terminate the service and Users' Accounts, prohibit access to the Site and
      its content, services and tools, delay or remove hosted content, and take
      technical and legal steps to keep Users off the Site if we think that they
      are creating problems or possible legal liabilities, infringing the
      intellectual property rights of third parties, or acting inconsistently
      with the letter or spirit of these Terms. Additionally, we may, in
      appropriate circumstances and at our discretion, suspend or terminate
      Accounts of Users for any reason, including without limitation: (1)
      attempts to gain unauthorized access to the Site or another User's account
      or providing assistance to others' attempting to do so, (2) overcoming
      software security features limiting use of or protecting any content, (3)
      usage of the Service to perform illegal activities such as money
      laundering, illegal gambling operations, financing terrorism, or other
      criminal activities, (4) violations of these Terms of Service, (5) failure
      to pay or fraudulent payment for Transactions, (6) unexpected operational
      difficulties, or (7) upon the request of law enforcement or other
      government agencies, if deemed to be legitimate and compelling by
      BITNIXIE, acting at its sole discretion.
    </p>

    <p>
      11.3. BITNIXIE also reserves the right to cancel unconfirmed Accounts
      or Accounts that have been inactive for a period of six (6) months or
      more, and/or to modify or discontinue our Site or Service. The User agrees
      that BITNIXIE will not be liable to them or to any third party for
      termination of their Account or access to the Site.
    </p>

    <p>12. SERVICES AVAILABILITY</p>

    <p>
      12.1. All Services are provided “AS IS”, without guarantees of any kind,
      either express or implied.
    </p>

    <p>
      12.2. BITNIXIE will strive to keep the Site up and running; however,
      all online services suffer from occasional disruptions and outages, and
      BITNIXIE is not liable for any disruption or loss you may suffer as a
      result. Thus, BITNIXIE does not provide any guarantees that access to
      the Site will not be interrupted, or that there will be no delays,
      failures, errors, omissions or loss of transmitted information.
    </p>

    <p>
      12.3. BITNIXIE will use reasonable endeavors to ensure that the User
      can normally access the Site in accordance with the Terms of Service.
      BITNIXIE may suspend use of the Site for maintenance and will make
      reasonable efforts to give the User notice. The User acknowledges that
      this may not be possible in an emergency.
    </p>

    <p>13. TAXATION</p>

    <p>
      13.1. The User undertakes to pay all his/her taxes and duties, which can
      be resulted from the use of BITNIXIE Services and should be paid
      according the User’s state of residence regulations.
    </p>

    <p>
      13.2. BITNIXIE does not responsible for any violation made by the User
      due to his/her obligation to calculate and pay taxes and duties.
    </p>

    <p>14. NOTICES AND COMMUNICATION</p>

    <p>
      14.1. BITNIXIE reserves the right to send notices to, and communicate
      with the User by any means of communication, available to BITNIXIE,
      considering the contact details provided by the User.
    </p>

    <p>
      14.2. The User expressly agrees to receive any notices in electronic form,
      and to be bound by them, if so is required by the Terms of Service.
    </p>

    <p>15. GOVERNING LAW AND DISPUTE RESOLUTION</p>

    <p>
      These Terms of Service shall be governed by and construed in accordance
      with the laws of the Lithuanian Republic, unless otherwise expressly
      provided. All disputes and controversies arising out of or in connection
      with this website and these Terms of Service shall be submitted to the
      Lithuanian Republic Court in Vilnus. If any portion of these Terms of
      Service shall be deemed invalid, void, or for any reason unenforceable,
      such portion shall be deemed severable and shall not affect the validity
      and enforceability of any remaining portion.
    </p>

    <p>16. LIMITATION OF LIABILITY</p>

    <p>
      15.1. IN NO EVENT SHALL BITNIXIE, ITS OFFICERS, DIRECTORS, EMPLOYEES,
      AGENTS, AND ALL THIRD PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR ANY
      OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
      PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING ANY THAT MAY
      RESULT FROM (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II)
      THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH
      HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (III) THE
      SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS,
      BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (IV) PERSONAL INJURY OR
      PROPERTY DAMAGE OF ANY NATURE WHATSOEVER, (V) THIRD-PARTY CONDUCT OF ANY
      NATURE WHATSOEVER, (VI) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS
      AND/OR ANY AND ALL CONTENT, PERSONAL INFORMATION, FINANCIAL INFORMATION OR
      OTHER INFORMATION AND DATA STORED THEREIN, (VII) ANY INTERRUPTION OR
      CESSATION OF SERVICES TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH
      HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VIII) ANY
      VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED
      TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER
      ADVERTISING OR OTHERWISE) TO THIS SITE, (IX) ANY USER CONTENT OR CONTENT
      THAT IS DEFAMATORY, HARASSING, ABUSIVE, HARMFUL TO MINORS OR ANY PROTECTED
      CLASS, PORNOGRAPHIC, “X-RATED”, OBSCENE OR OTHERWISE OBJECTIONABLE, AND/
      OR (X) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF
      THIS SITE OR THE SERVICES FOUND AT THIS SITE, WHETHER BASED ON WARRANTY,
      CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT
      BITNIXIE IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ADDITION,
      YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION ARISING
      OUT OF OR RELATED TO THIS SITE OR THE SERVICES FOUND AT THIS SITE MUST BE
      COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE
      SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED. IN ADDITION, YOU
      SPECIFICALLY ACKNOWLEDGE AND AGREE THAT IN NO EVENT SHALL BITNIXIE’S
      TOTAL AGGREGATE LIABILITY EXCEED THE TOTAL AMOUNT PAID BY YOU FOR THE
      PARTICULAR SERVICES THAT ARE THE SUBJECT OF THE CAUSE OF ACTION. THE
      FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
      PERMITTED BY LAW, AND SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS
      AGREEMENT OR YOUR USE OF THIS SITE OR THE SERVICES FOUND AT THIS SITE.
    </p>

    <p>16. INDEMNITY</p>

    <p>
      The User agrees to protect, defend, indemnify and hold harmless
      BITNIXIE and its officers, directors, employees, agents, and third
      party service providers from and against any and all claims, demands,
      costs, expenses, losses, liabilities and damages of every kind and nature
      (including, without limitation, reasonable attorneys’ fees) imposed upon
      or incurred by BITNIXIE directly or indirectly arising from (i) the
      User’s use of and access to this Site or the Services found at this Site;
      (ii) the User’s violation of any provision of the Terms of Service or the
      policies or agreements which are incorporated herein; and/or (iii) the
      User’s violation of any third-party right, including without limitation
      any intellectual property or other proprietary right. The indemnification
      obligations under this section shall survive any termination or expiration
      of the Terms of Service or the User’s use of this Site or the Services
      found on this Site.
    </p>

    <p>17. MODIFICATIONS AND AMENDMENTS</p>

    <p>
      BITNIXIE&nbsp;reserves the right to amend or modify any portion of
      these Terms of Service at any time by publishing the revised version of
      the Terms of Service on the Site. The changes will become effective, and
      shall be deemed accepted by the User, the first time the User uses the
      Services after the publishing of the revised Terms of Service and shall
      apply on a going-forward basis with respect to any activity initiated
      after the publishing. In the event that the User does not agree with any
      such modification, the User's sole and exclusive remedy is to terminate
      the use of the Services and close the Account. The User agrees that
      BITNIXIE shall not be liable to the User or any third party as a
      result of any losses suffered by any modification or amendment of these
      Terms of Service.
    </p>

    <p>18. FORCE MAJEURE</p>

    <p>
      If BITNIXIE is unable to perform the Services outlined in the Terms of
      Service due to factors beyond its control including but not limited to an
      event of Force Majeure, change of law or change in sanctions policy
      BITNIXIE will not have any responsibility to the User with respect to
      the Services provided hereunder and for a time period coincident with the
      event.
    </p>

    <p>19. LINKS TO THIRD-PARTY WEBSITES</p>

    <p>
      This Site and the Services found at this Site may contain links to
      third-party websites that are not owned or controlled by BITNIXIE.
      BITNIXIE&nbsp;assumes no responsibility for the content, terms and
      conditions, privacy policies, or practices of any third-party websites. In
      addition, BITNIXIE does not censor or edit the content of any
      third-party websites. By using this Site or the Services found at this
      Site, you expressly release BITNIXIE from any and all liability
      arising from your use of any third-party website. Accordingly,
      BITNIXIE encourages you to be aware when you leave this Site or the
      Services found at this Site and to review the terms and conditions,
      privacy policies, and other governing documents of each other website that
      you may visit.
    </p>
  </div>
);
export const refund = (
  <div class="text">
    <p>1. BACKGROUND</p>

    <p>
      BITNIXIE guarantees to any its User his/her right for refund, in
      case if such User is not satisfied with the quality of the provided
      Services.
    </p>

    <p>
      Furthermore, BITNIXIE declares that its User has the right to
      change his/her mind in case if the User’s Account was already funded and
      to request his/her Funds to be returned.
    </p>

    <p>NOTE:</p>

    <p>
      This Refund Policy concerns exclusively Transaction fees and Users’ Funds.
    </p>

    <p>
      This Refund Policy does not cover any transactions between the Users
      related to purchase-sale of crypto-currencies
    </p>

    <p>
      BITNIXIE undertakes to make its best efforts to assist the Users
      in case of any disputes related to refunds of purchased crypto-currencies.
    </p>

    <p>Refunds and Returns in excess of the original amount are prohibited.</p>

    <p>2. DEFINITIONS</p>

    <p>
      Business Day: means every official working day of the week in the Lithuanian
      Republic.
    </p>

    <p>
      Chargeback: means return of the User’s money by his/her Issuing Bank due
      to the disputed and/or fraudulent transaction.
    </p>

    <p>
      Eligibility Criteria: means the set of requirements that must be met for
      Refund or Return.
    </p>

    <p>Funding: (cf. Payment Policy).</p>

    <p>
      Return Form: means the BITNIXIE’s form to be completed by the
      User, who has requested for his/her Funds to be returned.
    </p>

    <p>Funds: (cf. Payment Policy).</p>

    <p>
      Issuing Bank: means the bank that has issued payment card to the User as
      Cardholder.
    </p>

    <p>
      Refund original amount: means the precise amount of Transaction fee
      deducted from the amount of certain User’s Transaction.
    </p>

    <p>
      Refund Form: means the BITNIXIE’s form to be completed by the
      User, who has requested the Refund.
    </p>

    <p>
      Refund: means giving back the amount of Transaction fee paid by the User
      with regard to certain Transaction.
    </p>

    <p>
      Return original amount: means the precise amount of the User’s Funds
      accumulated on his/her Account.
    </p>

    <p>
      Return: means giving back the User’s Funds transferred to his/her Account.
    </p>

    <p>
      Transaction Charges: means any fee charged by a bank/financial institution
      upon processing a valid refund request.
    </p>

    <p>
      All other terms and definitions in this Refund Policy are used in the same
      meaning as under the Terms of Use.
    </p>

    <p>3. GENERAL CONDITIONS</p>

    <p>
      The User who has paid for the BITNIXIE Services, i.e. paid a
      transaction fee, or has funded his account may request a Refund or Return
      in accordance with the Eligibility Criteria as further set out herein.
    </p>

    <p>
      Refund/Return will only be considered where the User complies with the
      Eligibility Criteria in full. Where the User fails to meet any of the
      Eligibility Criteria, BITNIXIE shall have the right, in its sole
      discretion, to decline the User’s request for a Refund/Return.
    </p>

    <p>
      In order to apply for a Refund/Return, the User must request and complete
      a Refund Form/Return Form and send the respective form to the Bitnixie UAB support address.
    </p>

    <p>
      You can obtain the Refund/Return Form under request sent to contact@bitnixie.lt.
    </p>

    <p>
      To prevent Prohibited Conduct, all payments and information related to
      Refund/Return may be verified by BITNIXIE. In such case, Bitnixie UAB may request the User to provide certain documents, including, but
      not limited to, identification documents, copy of the User’s Payment Card
      and Invoice or/and any other prove of the fact that disputed payment was
      made. In case if the User fails to provide appropriate documents or
      information within three (3) days upon the BITNIXIE request or in
      case of any doubts as to authenticity of provided documents, Bitnixie UAB
      &nbsp;shall be entitled to decline the User’s Refund/Return request.
    </p>

    <p>
      BITNIXIE shall process the User’s Refund Form/Return Form as soon
      as is reasonably practicable. Response times will vary depending on stated
      reasons for the request. In any case, BITNIXIE shall notify the
      User on the outcome of the request in accordance with the timescales set
      out herein.
    </p>

    <p>
      Refund/Return request will only be approved or declined after meticulous
      verification made by BITNIXIE.
    </p>

    <p>
      NOTE: Submission of Refund Form/Return Form does not guarantee that the
      User’s request will be satisfied.
    </p>

    <p>4. REFUND ELIGIBILITY CRITERIA</p>

    <p>
      (i) All requests for Refund will only be accepted where notice is received
      by BITNIXIE no later than forty-eight (48) hours after made
      Transaction.
    </p>

    <p>
      (ii) Notice mentioned in para (i) above should be provided to Bitnixie UAB by completing Refund Form.
    </p>

    <p>
      (iii) Completed Refund Form should be sent to the BITNIXIE
      electronic address with 'Refund Request' in the subject line.
    </p>

    <p>5. RETURN ELIGIBILITY CRITERIA</p>

    <p>
      (i) All requests for Refund should be provided to BITNIXIE by
      completing Return Form.
    </p>

    <p>
      (ii) Completed Return Form should be sent to the Bitnixie UAB
      &nbsp;electronic address with 'Return Request’ in the subject line.
    </p>

    <p>
      (iii) Return is made only via Bank transfer and after the User provides
      all details for such Bank transfer.
    </p>

    <p>6. PROCESSING TIMELINE</p>

    <p>
      Within fifteen (15) Business Days as of the date of Refund From/Return
      Form receipt, BITNIXIE shall contact the User to request further
      information (if required) and the User shall provide such information as
      soon as reasonably practicable, but in any case no later than fourteen
      (14) days following such request.
    </p>

    <p>
      BITNIXIE shall notify the User by e-mail on its final decision
      regarding Refund/Return:
    </p>

    <p>
      within fifteen (15) Business Days following the receipt of the last
      requested additional document or information;
    </p>

    <p>
      within twenty (20) Business Days following the receipt of Refund
      Form/Return Form, if no further information or document is requested.
    </p>

    <p>
      Refund/Return will be processed within twenty (20) Business Days from the
      date of notifying the User on the BITNIXIE decision.
    </p>

    <p>The User will have no Refund/Return:</p>

    <p>
      When requested by BITNIXIE to do so, the User fails to provide
      BITNIXIE with information or/and document(s) within the terms set
      herein.
    </p>

    <p>
      If BITNIXIE suspects that the User has, or is, engaged in, or have
      in any way been involved in, fraudulent or illegal activity, including
      Prohibited Conduct.
    </p>

    <p>There is no ground for Refund/Return.</p>

    <p>
      NOTE: BITNIXIE reserves the right to lock the User’s Funds while
      Refund/Return investigation is in process, which means that the Funds
      could be inaccessible during such investigation.
    </p>

    <p>7. CHARGEBACKS</p>

    <p>
      BITNIXIE&nbsp;expects the User to contact it using Bitnixie UAB
       contact details to resolve any problem or issue related to his/her
      payments, before the User makes any Chargeback request. This Section does
      not affect any rights and/or claims, which the User may have against the
      bank/financial institution.
    </p>

    <p>
      BITNIXIE will investigate any Chargeback requests made by the User
      and in response will inform the User’s Issuing Bank whether any Service or
      Transaction has been cancelled.
    </p>

    <p>
      BITNIXIE&nbsp;reserves the right to suspend User's account and
      lock User's Funds during the chargeback investigation procedure.
    </p>

    <p>8. MISCELLANEOUS</p>

    <p>
      Any charges, which arise upon processing Refund/Return, shall be borne
      solely by the User. Such charges will be deducted from the final amount of
      Refund/Return.
    </p>

    <p>
      This Refund Policy will be amended from time to time if there is any
      change in the legislation. Terms and conditions of the Refund Policy are
      subject to change by BITNIXIE and in the case of any amendments,
      BITNIXIE will make an appropriate announcement. The changes will
      apply after BITNIXIE has given notice.
    </p>

    <p>
      In case if the User does not wish to accept the revised Refund Policy,
      he/she should not continue to use BITNIXIE Services. If the User
      continues to use the Services after the date on which the change comes
      into effect, his/her use of the Services to be bound by the new Refund
      Policy.
    </p>
  </div>
);
