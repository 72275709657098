import React from 'react';
import './Header.scss';
import Button from '../Button';
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    return (
        <header className='header__main'>
            <div className="header__main_inner">
                <div onClick={() => navigate('/')} className="logo_container">
                    <img src="svg/logo.svg" alt="" width={200} height={40} />
                </div>
                <ul>
                    <li><a href='/#about'>About</a></li>
                    <li><a href='/#services'>Services</a></li>
                    <li><a href='/support'>Support</a></li>
                    <li><a href='/#contact'>Contact</a></li>
                </ul>
                <Button onClick={() => window.open('https://my.bitnixie.lt', '_self')} outlined>Sign In</Button>
            </div>

        </header>
    );
};

export default Header;

