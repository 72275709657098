import React from 'react';
import './Button.scss';

const Button = ({ children, onClick, outlined = false, ...props }) => {
    return (
        <div className="cl_button__wrapper">
            <button onClick={onClick} className={`cl_button${outlined ? '' : ' cl_button__filled'}`} {...props}>
                {children}
            </button>
        </div>
    );
};

export default Button;
