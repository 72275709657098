import {Navigate, Route, Routes} from "react-router-dom";
import App from "../../App";
import Support from "../../pages/Support";

export const ROUTES = Object.freeze({
	MAIN: "/",
	SUPPORT: "/support",
});
const routes = Object.freeze([
	{path: ROUTES.MAIN, element: <App/>},
	{path: ROUTES.SUPPORT, element: <Support/>},
]);

const AppRouter = () => {
	return (
		<Routes>
			{routes.map(route => (
				<Route key={route.path} path={route.path} element={route.element} exact />
			))}
			<Route path="*" element={<Navigate to={ROUTES.MAIN} />}/>
		</Routes>
	);
};

export default AppRouter;
