import React from 'react';
import './Footer.scss';
import Button from '../Button';
import { privacy, aml, terms, refund } from '../../policy_text';
import {useNavigate} from "react-router-dom";
import aml_policy from '../../assets/AML_POLICY.pdf';

const Footer = ({ openModal }) => {
    const navigate = useNavigate();
    return (
        <div className='footer__wrapper'>
            <footer class='footer__main'>

                <div className="logo_container">
                    <img src="svg/logo_light.svg" alt="" />
                </div>
                <div className="footer__col">
                    <div className="">
                        <ul>
                            <li onClick={() => navigate('/support')} > <a>Privacy Policy </a></li>
                            <li><a href={aml_policy} download="aml_policy.pdf">AML/KYC Policy</a></li>
                            <li onClick={() => openModal({ isOpen: true, header: 'Terms of service', body: terms })}><a>Terms of service</a></li>
                            <li onClick={() => openModal({ isOpen: true, header: 'Refund and return policy', body: refund })}><a>Refund and return policy</a></li>
                        </ul>
                        <p>KONSTITUCIJOS PR. 21A, LT-08130, VILNIUS, LITHUANIA</p>
                    </div>
                </div>
                {/* <Button>Sign In</Button> */}

            </footer >
        </div >

    );
};

export default Footer;

