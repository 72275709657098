import React from 'react';
import './Container.scss';

const Container = ({ children, id, bordered, ...props }) => {
    return (
        <div id={id} className={`container ${bordered ? 'bordered' : ''}`} {...props}>
            {children}
        </div >
    );
};

export default Container;
