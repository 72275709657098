import "./modal.scss";
import React from "react";

class Modal extends React.Component {
  constructor(props) {
    super(props);
  }

  //   shouldComponentUpdate(nextProps) {
  //     return !I.is(this.props.data, nextProps.data);
  //   }

  render() {
    let isOpen = this.props.data["isOpen"];
    debugger;
    return (
      <div className={`flex middle center modal ${isOpen ? "open" : ""}`}>
        <h1
          onClick={this.props.closeModal}
        >
          ✖
        </h1>
        {isOpen ? (
          <div className={`row modal-content ${this.props.data.size || ""}`}>
            <div className="col-12 middle modal-title">
              {this.props.data.title}
            </div>
            <div className="col-12 modal-body">{this.props.data.body}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Modal;
